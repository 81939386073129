




































































































































































































import ProgramEventComponent from "@/popupcomponents/programeventcomponent/ProgramEventComponent";
export default ProgramEventComponent;
